.login-body {
  font-size: 18px;
}

.login-design__column {
  background: #002333;
  color: white;
  /* max-width: 309px; */
  font-family: "Arimo";
  min-height: 568px;
}

.login-design__start {
  padding: 0px 30px;
  margin-top: 35%;
}

.login-header {
  font-size: 20px;
  min-height: 40px;
  font-weight: 700;
  margin: 10px 0 10px 0;
  line-height: 22px;
  margin-top: 20px;
}

.login-devider {
  font-weight: 900;
  border-top-color: rgba(255, 255, 255, 1);
}

.login-text {
  font-size: 14px;
  font-family: "Basier Square";
}

.login-form__column {
  width: 100%;
  max-width: 480px;
  min-width: 350px;
  font-family: "Arimo";
  min-height: 568px;
}

.login-row {
  background-color: #f6f6f6;
  justify-content: center;
}

.auth-button {
  color: #002333;
  font-size: 16px;
  min-height: 40px;
  width: 100%;
  font-weight: 700;
  margin: 10px 0 10px 0;
  border: 1px solid #002333;
}

.normal-input-more-radius {
  border-radius: 2px;
}

.inline-input {
  font-family: "Arimo";
  font-weight: 500;
  font-size: "16px";
  /* height: 40px; */
  /* max-width: 161px; */
  border-radius: 2px;
  text-align: left;
}

.inline-input-small {
  font-family: "Arimo";
  font-weight: 500;
  font-size: "16px";
  height: 40px;
  border-radius: 2px;
  text-align: left;
  /* max-width: 161px;    */
}

.group-input {
  /* display: flex; */
  background-color: grey;
  max-height: 45px;
}

.last-name-class {
  margin-left: 5%;
}

@media screen and (max-width: 400px) {
  .inline-input {
    /* min-width: 271px; */
    /* width: 90%; */
  }

  .last-name-class {
    margin-left: 0;
    margin-bottom: 120px;
  }

  /* .inline-group__main {
        min-height: 100px;
    } */
}

.min-header {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px 0px;
}

.max-min-input {
  width: 100%;
  /* height: 35px; */
  /* max-width: 161px; */
  border-radius: 2px;
  text-align: left;
  /* min-width: 100px; */
}

.ant-form-item-explain-error > div {
  font-size: 12px;
  text-align: left;
}

.regiter-terms {
  max-width: 341px;
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin: 8px 0 20px 0;
}

.welcome-main {
  width: 272px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 35% auto auto auto;
}

.welcome-text {
  font-family: "Arimo";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.button-div {
  margin-top: 15%;
}

.select-profile__button {
  font-size: 16px;
  min-height: 40px;
  max-width: 336px;
  font-weight: 700;
  margin: 5px 0 5px 0;
  border: 1px solid #002333;
}

.graditutde-main {
  /* min-width: 240px; */
  padding: 0px 55px;
  /* max-width: 340px; */
  /* position: absolute; */
  /* margin: 35% auto auto auto; */
}

.verified-msg-content {
  display: grid;
  place-items: center;
}

.verification-success-info {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 42px;
  /* or 110% */

  text-align: center;
}

.graditude-text {
  font-family: "Arimo";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.graditude-sub-text {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10%;
}

.verify-button__div {
  display: grid;
  place-items: center;
  text-align: center;
  margin-top: 10%;
  width: 90%;
}

.verify-button {
  background: #35df90;
  min-height: 40px;
  max-width: 341px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Arimo";
  color: #ffffff;
}

.verify-button:hover,
.verify-button:focus {
  background: #35df90;
  color: #ffffff;
}

.reset-password-from__div {
  margin-top: 25px;
}

.reset-password-btn {
  background: #664ff3;
  font-size: 16px;
  font-weight: 700;
  border: 0px;
  height: 40px;
}

.reset-password-btn:hover,
.reset-password-btn:focus {
  background: #675ff3;
}

.reset-help {
  max-width: 359px;
  width: 359px;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  margin: 8px 0 20px 0;
}

.reset-password-header {
  font-family: "Arimo";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
}

.reset-password-footer {
  max-width: 359px;
  width: 359px;
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  margin: 8px 0 20px 0;
}

.login-small-btn {
  background: #35df90;
  min-height: 40px;
  max-width: 341px;
  font-size: 18px;
  font-weight: 700;
  min-width: 183px;
  color: #ffffff;
  border-radius: 5px;
}

.login-small-btn:hover,
.login-small-btn:focus {
  background: #35df90;
  color: #ffffff;
}

.login-input {
  font-family: "Arimo";
  height: 40px;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}

.mobile-header-sub {
  font-family: "Basier Square";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}

.login-divider {
  font-weight: 900;
  border-top-color: rgba(0, 0, 0, 1);
  max-width: 341px;
  min-width: 100px;
}

.normal-input {
  width: 100%;
  font-family: "Arimo";
  font-weight: 500;
  font-size: "16px";
  /* height: 45px; */
  border-radius: 2px;
  text-align: left;
}

/* .normal-input.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 50px;
} */

.login-form-button,
.login-form-button:active {
  color: #002333;
  width: 100%;
  min-height: 40px;
  /* max-width: 41px; */
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 30px;
  border: 1px solid #002333;
  text-align: center;
}

.buttonDiv {
  display: grid;
  place-items: center;
}

.login-primary-button,
.login-primary-button:hover {
  color: #ffffff;
  width: 100%;
  min-height: 40px;
  /* max-width: 41px; */
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 30px;
  background-color: #35df90;
}

.user-name-header {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}

.company-reg-form {
  margin-top: 30%;
  width: 75%;
}

.sub-header-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
}

.verfity-success-msg {
  padding-top: 35%;
  text-align: -webkit-center;
}
