.account-authentication {
  height: 48px;
  padding: 12px;
  background-color: white;
  font-family: 'Basier Square';
  font-weight: 400;
  font-size: 14px;
  vertical-align: middle;
}

.user-item-container {
  border-radius: 5px;
  padding: 2px;
  background-color: white;
  margin-bottom: 5px;
}

.edit-user-collapse {
  background-color: white;
  margin-bottom: 5px;
}

.edit-user-collapse.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  background-color: #f6f6f6;
  padding: 0px;
}

.account-authentication-header {
  height: 54;
  padding: 12px;
  background-color: #FAFAFA;
  font-family: 'Basier Square';
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px;
  margin-bottom: 2px;
}

.row-style {
  border-bottom: 2px solid #F6F6F6;
  height: 35px;
  margin-bottom: 15px;
}

.connect-subheader {
  color: black;
}

.connect-subheader::selection {
  color: black;
  border-bottom-color: #664FF3;
}

.mobile-header {
  font-family: "Arimo";
  font-weight: 700;
  font-size: 16px;
}

span.form-label {
  font-family: 'Basier Square';
  font-weight: 600;
  font-size: 12px;
  color: #A5A6A7;
}

.user-authentication-input {
  /* width: fit-content; */
  height: 40px;
  font-family: 'Basier Square';
  font-weight: 500;
  font-size: 14px;
}

.user-authentication-button {
  /* width: fit-content; */
  height: 40px;
  font-family: "Arimo";
}

.div-sub-heading {
  margin: 20px 0px;
  font-family: 'Basier Square';
  font-size: 18px;
  font-weight: 600;
}

.icon-align {
  font-size: 16px;
  font-weight: 600;
  padding-top: 5px;
}

.user-authentication-form {
  background-color: white;
  display: grid;
  place-items: center;
}

@media screen and (max-width: 400px) {
  .inline-input {
    min-width: 271px;
  }

  .last-name-class {
    margin-left: 0;
    margin-bottom: 120px;
  }

  .inline-group__main {
    min-height: 100px;
  }
}

.add-user-btn {
  font-Family: 'Arimo';
  font-weight: 700;
  font-size: 16px;
  background-color: #002333;
  color: white;
  border-radius: 5px;
  height: 45px;
  margin-bottom: 20px;
}

.add-user-btn:active,
.add-user-btn:focus {
  background-color: #002333;
  color: white;
}

.add-user-btn:hover {
  background-color: #002333;
}

.menu-group-item-header {
  color: #FFF;
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

}

.menu-group-item {
  font-family: "Basier Square";
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 200;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}