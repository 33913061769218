.chat-container {
  border-right: 1px solid #F6F6F6;
  height: 100%;
  text-align: center;
  width: 380px;
  display: flex;
  flex-Direction: column;
}

.chat-container-mobile {
  /* border-right: 1px solid #F6F6F6; */
  height: 100%;
  text-align: center;
  /* width: 380px; */
  /* display: flex; */
  /* flex-Direction: column; */
}

.chat-search-box {
  padding: 15px;
  border-bottom: 1px solid #F6F6F6;
  font-family: "Arimo";
}

.contact-card {
  display: flex;
  padding: 24px;
  margin: 14px;
  flex-basis: 45%;
  /* width: 341px; */
  cursor: pointer;
  border-radius: 5px;
}

.contact-card:hover {
  background-color: #F6F6f6;
}

.contact-card-mobile {
  display: flex;
  padding: 12px 24px 12px 24px ;
  margin: 14px;
  flex-basis: 45%;
  cursor: pointer;
  border-bottom: 1px solid #C4C4C4;
}