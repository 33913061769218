.notification {
  background-color: #002333;
}

.ant-badge-count {
  transform-origin: 0 px;
}

.ant-badge-multiple-words {
  padding: 0 3px;
}

.mobile-notification .ant-drawer-body {
  background-color: #002333;
}

.mobile-notification .ant-drawer .ant-drawer-content {
  background-color: #002333;
}

.mobile-notification .ant-drawer-wrapper-body {
  background-color: #002333;
  align-items: center;
}

.mobile-notification .ant-drawer-header {
  width: 100%;
}

.mobile-notification .ant-drawer-header {
  background-color: #002333;
  color: white;
}

.mobile-notification .ant-drawer-title {
  color: white;
}

.mobile-notification .ant-drawer-close {
  color: white;
}

.notification .cancel-btn {
  background-color: #664ff3;
  margin-right: 5px;
  height: 35px;
  width: 35px;
  color: white;
  border: 0px;
}

.notification .ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: #35df90;
}

.notification-back-icon {
  padding: 10px 20px;
  display: inline-flex;
}

span.notification-header {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #a5a6a7;
}

.notification-header-web {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #a5a6a7 !important;
  margin-bottom: 12px;
}

.notification-content {
  font-family: Basier Square;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.notification-btn-accept {
  height: 35px;
  width: 35px;
  color: white;
  border: 0px;
}

.notification-btn-cancel {
  margin-right: 10px;
  height: 35px;
  width: 35px;
  color: white;
  border: 0px;
}

.notification-popup {
  background-color: blue;
}

.notification-content .ant-popover-inner-content {
  background-color: #002333 !important;
}

.notification-body {
  height: 400px;
  overflow: auto;
  padding: 0px 5px;
  min-width: 335px;
}

.notification-body::-webkit-scrollbar {
  width: 7px;
  /* width of the entire scrollbar */
}

.notification-body::-webkit-scrollbar-track {
  background: #00000000;
  /* color of the tracking area */
}

.notification-body::-webkit-scrollbar-thumb {
  background-color: #35df90;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  /* border: 3px solid #002333; */
  /* creates padding around scroll thumb */
}

.menu .nav-button-settings {
  color: white;
  font-family: "Basier Square";
  font-weight: 500;
  font-size: 14px;
  padding: auto 0px;
}

.menu .nav-button-settings::selection {
  color: #35df90;
  /* background-color: transparent; */
}

.logo {
  display: inline;
  height: 54px;
  padding: 5px;
  padding-right: 25px;
}

.mobile-logo {
  display: inline;
  height: 54px;
  padding: 5px;
  /* padding-right: 20px; */
}

.nav-button {
  color: white;
  font-family: "Arimo";
  height: 64px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  border: 0px;
  background-color: #002333;
  /* line-height: 22px; */
}

.nav-button-dropdown {
  color: #fff;
  font-family: "Arimo";
  height: 64px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  border: 0px;
  background-color: #002333;
  /* line-height: 22px; */
}

.nav-button-dropdown:active {
  color: #35df90;
  font-family: "Arimo";
  height: 64px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  border: 0px;
  background-color: #002333;
  /* line-height: 22px; */
}

.nav-button.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  border-bottom: 0px;
}

.nav-button.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-bottom: 0px;
}

.mobile-nav-items {
  color: #fff;
  text-align: center;
  float: left;
  width: 25%;
  background-color: #002333;
}

.mobile-nav-items.ant-menu-item-selected {
  color: #35df90;
}

.mobile-nav-items-icon {
  font-size: 20px;
}

.mobile-nav-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 101%;
  color: white;
  text-align: center;
  z-index: 999;
  height: 45px;
}

.web-divider {
  background-color: #a5a6a7;
  margin-top: 0px;
  margin-bottom: 24px;
}

.notification-icon {
  background-color: #00000000;
  right: 5px;
}

.notification-icon-badge {
  padding-top: 5px;
  color: #fff;
  font-size: 1.5em;
}

.mobile-notification-icon {
  background-color: transparent;
  border: 0px;
  color: white;
  width: 64px;
}

.secondary-button {
  background-color: #664ff3;
  color: white;
  border-radius: 3px;
  border: 0px;
  height: 40px;
}

.nav-button.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  background-color: #0000;
  border-bottom: 0px;
}

.chat-menu-icon-avatar {
  position: relative;
  background-color: #0000;
  top: 4px;
  overflow: visible;
}

.notification-accepted-connection {
  height: 32px;
  background-color: #35df90;
  padding: 6px 10px;
  color: white;
  text-align: center;
  border-radius: 2px;
}

.mobile-sub-menu {
  background-color: white;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
}

.mobile-sub-menu-item {
  height: 36px;
  padding-top: 4px;
}

.mobile-sub-menu-divider {
  margin: 15px 0px;
  background-color: #a5a6a7;
}

.mobile-sub-menu-logo {
  font-size: 22px;
  color: #a5a6a7;
  margin-right: 18px;
}

.mobile-sub-menu-logo-end {
  font-size: 18px;
  color: #002333;
  margin-right: 18px;
  position: relative;
  float: right;
}

.mobile-sub-menu-header {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #a5a6a7;
  margin-top: 4px;
  margin-bottom: 24px;
}

.header-wallet {
  background-color: #35df90;
  height: 40px;
  width: 68px;
  margin-right: 20px;
  border-radius: 5px;
  font-family: "Arimo";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.header-wallet-mobile {
  background-color: #35df90;
  height: 28px;
  width: 56px;
  border-radius: 5px;
  font-family: "Arimo";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.font-bold-arimo {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  color: white;
  font-size: 30px;
  line-height: 44px;
  text-align: center;
}

.wallet-widget-web {
  background-color: #002333;
  border-radius: 10px;
  padding: 35px;
}

.wallet-widget-btn {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  border: 0px;
}

.wallet-bold-text {
  font-family: "Arimo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
}

.wallet-padding-left-50px {
  padding-left: 50px;
}

.wallet-padding-left-5px {
  padding-left: 5px;
}

.wallet-padding-bottom-52px {
  padding-bottom: 52px;
}

.text-line-height {
  color: #002333;
  line-height: 20px;
  margin-left: 0;
}

.wallet-stepper-icon {
  padding: 5px;
  border: 1px #cdcdcd solid;
  border-radius: 50%;
}

.wallet-large-text {
  font-family: "Arimo";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.wallet-normal-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.wallet-sub-header-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.wallet-widget-text-bold {
  font-size: 30px;
  line-height: 44px;
  text-align: center;
}

.center-text {
  display: grid;
  place-items: center;
}

.payment-header {
  font-family: "Arimo";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-block-end: 10px;
}

.payment-button {
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  background-color: #35df90;
}

.payment-button-button {
  background-color: #35df90;
  color: white;
  border-radius: 50px;
  min-width: 200px;
  height: 50px;
  font-size: 16px;
}

.payment-button-button:hover {
  color: white;
}

.payment-sub-header {
  font-family: "Arimo";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
}

.account-activation {
  font-family: "Basier Square";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  width: 100%;
  background-color: #002333;
  padding: 12px;
  color: #fff;
  text-align: center;
}

.dot {
  height: 6px;
  width: 6px;
  background-color: #35df90;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
