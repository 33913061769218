.chat-contact-list-header-mobile {
  font-family: Basier Square;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
}

.chat-send-button {
  color: #664FF3;
  padding-right: 10px;
}
