@font-face {
  font-family: "Arimo";
  src: local("GTWalsheimPro-Black"),
    url(./fonts/font/GTWalsheimPro-Black.woff) format("woff");
}

@font-face {
  font-family: "Basier Square";
  src: local("Basier-Square"),
    url(./fonts/Basier/BasierSquare-Regular.otf) format("woff");
}

.display-linebreak {
  white-space: pre-wrap;
}
