.profile-picture {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.invoice-header {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
  font-weight: 700;
}

.invoice-input-label {
  color: #a5a6a7;
  font-weight: 600;
}

.button-stack {
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
}

.cancel-button {
  width: 50%;
  font-weight: 700;
  background-color: #664ff3;
  border-width: 0;
  color: white;
  height: 40px;
}

.accept-button {
  width: 50%;
  font-weight: 700;
  border-width: 0;
  height: 40px;
}

.invoice-input {
  /* padding-left: 10%; */
  padding: 12px 7.5% 0px 7.5%;
  padding-right: 50px;
  border-bottom: 3px solid #f9f9fc;
}

.invoice-input-column-left {
}

.invoice-input-column-right {
  border-right: 3px solid #f9f9fc;
}

.invoice-input-row {
  /* border-top: 3px solid #F9F9FC; */
}

span.highlighted-text {
  font-family: "Basier Square";
  font-size: 16px;
  font-weight: 700;
}

/* .duration-row{
  display: flex;
}

.date-pickes{
  display: flex;
  width: 50px;
} */
