p.ant-upload-text {
  font-family: "Basier Square";
  font-size: 14px !important;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  text-align: center;
  padding-top: 35px;
}

.edit-profile-input {
  height: 40px;
  border-radius: 2px;
}

.form-column {
  height: "54px";
}

.form-button {
  height: 40px;
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 20px;
  width: 85%;
}

.form-button-block {
  height: 40px;
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 20px;
  width: 92%;
}

.inner-sub-header {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px 20px 0px 20px;
}

.profile-sub-selection {
  color: #002333;
  background-color: white;
  margin-top: 0;
}

.profile-sub-selection.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #002333;
  color: white;
  margin-top: 0;
}

.mobile-layout {
  background-color: red;
}

.freelancer-edit-profile .ant-upload.ant-upload-select-picture-card {
  border-radius: 100%;
}

.profile_picture {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.btn-upload {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #595959;
}

span.profile-edit-header {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #595959;
}
