@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap");

@primary: #35df90;
@background: #f6f6f6;
@primary-dark: #002333;
@secondary: #664ff3;

@primary-font: "Basier Square";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #00000000 inset !important;
}

.custom__scrollbar::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
  background-color: @background;
}

.custom__scrollbar::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #00000000;
  /* color of the tracking area */
}

.custom__scrollbar::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: @primary;
  /* color of the scroll thumb */
  border-radius: 25px;
  /* roundness of the scroll thumb */
  // border: 2px solid @background;  /* creates padding around scroll thumb */
}

.App {
  background-color: @background;
}

body {
  background-color: @background !important;
}

.container {
  width: 100%;
  display: flex;
  align-self: center;
  margin: auto;
  background-color: @background;
}

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);
.make-container(@screen-xxl-min, @screen-xxl);

.sub-menu-bar {
  padding-left: 100px;
  background-color: white;
}

// .sub-menu-item.ant-menu-item {
// color: @primary-dark  !important;
// }

.sub-menu-item {
  font-family: "Arimo";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #002333 !important;
}

.sub-menu-item.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected {
  color: @primary-dark;
  // border-bottom-color: @secondary;
  border-bottom: 2px solid @secondary;
}

.sub-menu-item.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
  color: @primary-dark;
  border-bottom: 2px solid @secondary;
}

.mobile-submenu-header {
  font-family: "Arimo";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 8px;
}

.mobile-divider {
  background-color: @primary-dark;
  padding: 0px 0px;
  margin: 5px 0px 15px 0px;
}

div {
  font-family: "Basier Square";
}

// .ant-btn:hover {
//   background-color: #0000;
// }

.anticon svg {
  display: block;
}

//Custom Radio Button
.ant-radio-checked .ant-radio-inner {
  border-color: @secondary;
  border-radius: 2px;
  background-color: #e7e7ed;
}

.ant-radio-inner::after {
  background-color: @secondary;
  border-radius: 2px;
  width: 10px;
  height: 10px;
  top: 2px;
  left: 2px;
}

.ant-radio-wrapper:hover .ant-radio {
  border-color: @secondary;
}

.ant-radio-inner {
  border-radius: 2px;
  background-color: #e7e7ed;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: @secondary;
}

//Custom Checkbox
.ant-checkbox-inner {
  background-color: #e7e7ed;
}

.ant-checkbox-inner:hover {
  border-color: #ffff;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @secondary;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @secondary;
  border-color: @secondary;
}

.ant-checkbox-checked::after {
  border-color: @secondary;
}

.mobile-sub-menu-items {
  color: white;
  text-align: center;
  height: 35px;
  background-color: #664ff3;
}

.mobile-sub-menu-items.ant-dropdown-menu-item:hover {
  background-color: #664ff3;
}

.mobile-sub-title-container {
  padding: 0 25px;
  background-color: #f6f6f6;
}

.border-style-row {
  border-bottom: 3px solid #f9f9fc;
}

.border-style-cell-right {
  border-right: 3px solid #f9f9fc;
}

.place-items-center-cell {
  display: grid;
  place-items: center;
}

.web-header {
  font-family: "Arimo";
  margin-right: 25px;
  font-size: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 42px;
  color: @primary-dark;
  margin-block-end: 10px;
}

.ant-input-affix-wrapper-lg {
  padding: 0 10 !important;
}

.ant-input-affix-wrapper > input.ant-input {
  box-shadow: none;
}

.ant-input-affix-wrapper {
  padding: 0px 11px;
}

.ant-select-selection-search-input,
.ant-picker-large .ant-picker-input > input {
  box-shadow: none !important;
}

element.style {
  background-color: rgb(0, 35, 51);
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
.form-button-block {
  height: 40px;
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 20px;
  width: 92%;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-btn-primary {
  color: #fff;
  border: 0px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
  text-shadow: 0px;
  box-shadow: 0px;
}

.wallet-table-row {
  color: #002333;
}

.align-gird-center-white-background {
  display: grid;
  place-items: center;
  background-color: #fff;
}

.login-registration-img-background {
  width: auto;
  height: 100%;
}

.login-registration-img-background-mobile {
  display: none;
}

@import "./modules/Work//style.less";

@primary-color: #35DF90;@body-background: #fff;@component-background: #FFF;@popover-background: @component-background;@btn-default-ghost-color: #FFF;@btn-default-ghost-bg: #664FF3;@btn-default-ghost-border: #664FF3;@menu-item-color: #FFF;@menu-item-active-bg: #00000000;@switch-color: #664FF3;@switch-bg: #FFF;@layout-body-background: #f6f6f6;