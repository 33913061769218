/* div {
  font-family: 'Basier Square';
} */

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.search-button {
  font-family: "Arimo";
  margin-right: 10px;
  height: 40px;
  font-weight: 700;
  border-radius: 4px;
  background-color: #f6f6f6;
  border: 1px solid #c7c8c7 !important;
}

.search-button-selected {
  font-family: "Arimo";
  margin-right: 10px;
  height: 40px;
  font-weight: 700;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #c7c8c7 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.sub-header {
  background-color: green;
  /*Overriden property*/
}

.web-content {
  padding: 1.5rem 100px;
  /* max-width: 1280px; */
  width: 100%;
  background-color: #f6f6f6;
  /* minWidth: '1280px', */
}

.chat-window {
  padding: 1.5rem 100px;
  max-width: 100%;
  background-color: #f6f6f6;
  /* minWidth: '1280px', */
}

.web-search {
  margin-right: 25px;
}

.mobile-content {
  padding: 0 25px;
  background-color: #f6f6f6;
}

.user-status {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.avatar-item {
  margin-right: 24px;
}

[class*="-col-rtl"] .avatar-item {
  margin-right: 0;
  margin-left: 24px;
}

.parent {
  position: relative;
  top: 10;
  left: 110;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  height: 72px;
  width: 72px;
  border-radius: 50%;
}

.image2 {
  position: absolute;
  top: 45px;
  left: 45px;
  height: 32px;
}

.cardSpecialization {
  background-color: "#35DF90";
  color: "white";
  height: 25px;
  padding: 3px;
  border-radius: "20%";
  display: "inline-block";
  margin-right: 5px;
}

.card-footer-item {
  background-color: "Highlight";
  text-align: "center";
  font-family: "Roboto, sans-serif";
  font-weight: 700;
  font-size: 14;
  color: "red";
}

.layout {
  align-items: center;
  background-color: #f6f6f6;
}

.mobile-layout {
  min-width: 320px;
  width: 400px;
  width: 100%;
  background-color: #f6f6f6;
}

span.webspecialization {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  display: "inline-block";
  margin-right: 2px;
  margin-bottom: 10px;
  color: #595a57;
}

span.rate {
  text-align: center;
  font-family: "Arimo";
  font-weight: 700;
  font-size: 16;
}

.web-divider {
  padding: 0px 0px;
  margin: 5px 0px 12px 0px;
}

.web-divider-dark {
  background-color: #002333;
  padding: 0px 0px;
  margin: 5px 0px 12px 0px;
}

span.card-name {
  text-align: center;
  font-family: "Basier Square";
  font-weight: 900;
  font-size: 14px;
}

.connection-loading {
  margin-top: 50px;
  text-align: center;
}

.card-body-content {
  font-family: "Basier Square";
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 25px;
  padding-right: 30px;
  color: #002333;
  font-weight: 400;
}

span.web-card-bottom {
  text-align: center;
  font-family: "Basier Square";
  font-weight: 400;
  font-size: 14px;
}

span.web-card-title {
  text-align: center;
  font-family: "Arimo";
  font-weight: 700;
  font-size: 18;
  margin-bottom: 0px;
}

span.mobile-rate {
  width: 100%;
  background-color: #35df90;
  color: white;
  padding: 5px 8px;
  border-radius: 5%;
  display: inline-block;
  margin: 10px;
  margin-top: 15px;
  text-align: center;
  font-family: "Basier Square";
  font-weight: 700;
  font-size: 12px;
}

span.mobile-specialization {
  font-family: "Basier Square";
  font-size: 11px;
  border-radius: 5px;
  margin-right: 2px;
  margin-top: 30px;
}

.card-mobile-name-bold {
  font-family: "Arimo";
  font-weight: 400;
  font-size: 18px;
}

.card-mobile-sub {
  font-size: 16px;
  font-weight: lighter;
  margin-bottom: 10px;
  font-family: "Basier Square";
  font-weight: 700;
}

.card-mobile-content1 {
  font-family: "Basier Square";
  font-size: 14px;
}

.card-mobile-content2 {
  font-family: "Basier Square";
  font-size: 14px;
  margin-bottom: 10px;
}

.mobile-select-list {
  width: 75%;
  fill: #664ff3;
  padding: 5px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  text-align: center;
}

.mobile-select-list-option {
  font-family: "Basier Square";
  font-size: 14px;
  background-color: #664ff3;
  color: #fff;
  fill: #664ff3;
  text-align: center;
  font-size: 16px;
}

/* .mobile-select-list-option.ant-select-item-option-content:hover {
  color: #FFF;
  background-color: #664FF3;
}

.mobile-select-list-option.ant-select-item-option-content {
  color: #FFF;
  fill: #664FF3;
  background-color: #664FF3;
}

.mobile-select-list-option.ant-select-dropdown {
  background-color: #664FF3;
}

.mobile-select-list-option.ant-select-dropdown-placement-bottomLeft {
  background-color: #664FF3;
}

.mobile-select-list-option.ant-select-item-option-selected {
  color: #FFF;
  background-color: #664FF3;
}

.mobile-select-list-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #664FF3;
}

.mobile-select-list-option.sub-menu-item.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover {
  color: black;
  border-bottom-color: #664FF3;
} */

.submenu::selection {
  color: black;
}

.card-inner-border {
  text-align: center;
  border-right: 1px solid #f6f6f6;
  border-top: 1px solid #f6f6f6;
  padding-bottom: 14px;
  padding-top: 12px;
}

.mobile-header {
  font-family: "Basier Square";
  font-weight: 700;
  margin: 0px;
  margin-top: 25px;
  font-size: 10px;
}

.mobile-sub-menu-button {
  width: 100%;
  font-family: "Basier Square";
  background-color: #664ff3 !important;
  color: #fff !important;
  border-right: 5px;
  text-align: center !important;
  font-size: 14px;
  padding: 5px 0px !important;
  height: 40px !important;
  border-radius: 5px !important;
}

.pagination {
  text-align: center;
  margin-top: 10px;
}
