.vacancy__row {
  background-color: #f6f6f6;

  @media screen and (min-width: @screen-md) {
    min-height: 75vh;
  }
}

.vacancy__wrapper {
  padding: 0 !important;

  @media screen and (min-width: @screen-md) {
    padding: 1.5rem 0px;
    overflow-y: scroll;
    min-height: 80vh;
    height: 85vh;
  }
}

.vacancies-freelancer-detail-serach-bar {
  background-color: #664ff3;
  width: 100%;
  position: fixed;
  bottom: 0px;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  padding: 16px;
  cursor: pointer;

  @media screen and (min-width: @screen-md) {
    width: 50% !important;
  }
}

.fl-projects__wrapper {
  padding: 0 !important;

  @media screen and (min-width: @screen-md) {
    padding: 0 !important;
    overflow-y: scroll;
    min-height: 86vh;
    height: 91vh;
  }
}

.fl-projects__hr_list__wrapper {
  height: 63vh;
  overflow-y: scroll;
}

.fl-projects__stats {
  flex: 27%;

  @media screen and (min-width: @screen-md) {
    flex: 28%;
  }

  @media screen and (min-width: @screen-md) {
    flex: 29%;
  }

  @media screen and (min-width: @screen-lg) {
    flex: 30%;
  }

  @media screen and (min-width: @screen-xl) {
    flex: 30%;
  }
}
