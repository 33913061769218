.message-window-container {
  text-align: center;
  flex: 1;
  margin: 1rem;
  border-right: 1px;
  border-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.message-window-container-mobile {
  height: calc(100% - 45px);
  /* border: 1px solid red; */
}

.message-window-header {
  padding: 15px;
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10%;
}

.message-window-header-mobile {
  display: none;
}

.message-display-panel {
  /* border: 2px solid red; */
  overflow-y: scroll;
  padding-right: 1rem;
  margin-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 50px;
  height: calc(85% - 35px);
}

.message-display-panel-mobile {
  height: 100%;
  overflow: auto;
  width: 100%;
  /* padding-top: 1rem;
  padding-bottom: 50px; */
  background-color: white;
  padding: 1rem 1rem 1rem 1rem;
}

.chat-back-icon {
  padding-top: 14px;
  padding-left: 8px;
}

.message-input-send-proposal-btn,
.message-input-send-proposal-btn:hover,
.message-input-send-proposal-btn:active {
  background-color: #35df90;
  padding-left: 8px;
  padding-right: 20px;
  margin-left: 4px;
  color: white;
  text-align: center;
  height: 40px;
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.message-input-send-proposal-btn-mobile,
.message-input-send-proposal-btn-mobile:hover,
.message-input-send-proposal-btn-mobile:active {
  background-color: #35df90;
  /* padding-left: 8px;
  padding-right: 20px; */
  /* margin-left: 4px; */
  color: white;
  text-align: center;
  /* height: 40px; */
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 110%;
  position: relative;
  top: -17px;
  height: 50px;
  left: -17px;
}

.message-input-form {
  width: 100%;
  display: flex;
  position: relative;
  bottom: 0px;
  background-color: white;
}

.mobile-menu-button-icon {
  padding-top: 5px;
  color: #002333;
  font-size: 1.5em;
}

.mobile-menu-button-icon-active {
  padding-top: 5px;
  color: #35df90;
  font-size: 1.5em;
}

.mobile-menu-button {
  background-color: #0000;
  width: 100%;
  height: 100%;
}

.mobile-menu-button:hover,
.mobile-menu-button:active,
.mobile-menu-button:focus {
  background-color: #0000;
}

.mobile-menu-icon-avatar {
  background-color: #0000;
  width: 50px !important;
}

.chat-delete .ant-popover-inner-content {
  background-color: white !important;
  /* width: 1000px; */
}
