.search-filter .ant-slider-track,
.search-filter .ant-slider-track:hover,
.ant-slider:hover .ant-slider-track {
  background-color: #664ff3;
}

.search-filter .ant-slider-handle {
  background-color: #664ff3;
  border-color: #fff;
}

.search-filter-label {
  font-family: "Arimo";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  width: 100% !important;
}

.custom-tooltip {
  background-color: #0000;
  color: #000233;
  padding: 0px;
  margin: 0px;
  min-height: 0px;
  box-shadow: #ffff;
  min-width: 25px;
}

.search-filter-selected {
  display: inline-block;
  font-family: "Arimo";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #664ff3;
  color: #fff;
  padding: 6px 12px;
  margin-right: 16px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.search-filter-selected .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: red !important;
}

.selected-filter {
  margin-block-end: 6px;
}

.detail-label {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #a5a6a7;
  margin-block-end: 5px;
}

div.freelancer-detail {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.freelancer-detail-header-column {
  text-align: center;
  /* padding: 24px; */
  font-family: "Arimo";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #000233;
  display: grid;
  place-items: center;
}

.freelancer-detail-cell {
  padding: 20px 36px;
}

.comapny-detail-cell {
  padding: 20px 62px;
}

.freelancer-detail-link {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.12em;
  text-align: center;
  color: #664ff3;
  margin-block-start: 10px;
}

.comapny-detail-header {
  font-family: "Arimo";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #000233;
}

.comapny-detail-header-button {
  border-bottom: 2px solid #000233;
  font-size: 26px;
  color: #000233;
  margin-block-end: 10px;
}

.btn-font {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: 0px;
}

.filter-mobile-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.card-normal-text {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.card-bold-text-underlined {
  font-family: Basier Square;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: underline;
}

.card-bold-text {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;
}

.card-normal-small {
  display: block;
  max-height: 58px;
  overflow: scroll;
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-block-end: 10px;
}

.search-mobile-filter-btn {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}

.vacancy-interested-btn {
  background-color: #35df90;
  color: #fff;
}

.vacancy-interested-btn:hover {
  background-color: #45efa1;
}

.vacancy-interested-btn-disabled {
  background-color: #dfdfdf !important;
}

.vacancy-notinterested-btn {
  background-color: #664ff3;
  color: #fff;
}

.vacancy-notinterested-btn:hover {
  background-color: #775ff4;
  color: #fff;
}

.vacancy-notinterested-btn-disabled {
  background-color: #dfdfdf !important;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
