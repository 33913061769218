.invoice-table-header {
  align-self: center;
  padding-left: 16px;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.data-center {
  text-align: center;
}

.invoice-table-header-row {
  border-radius: 4px;
  height: 54px;
  background-color: #FAFAFA;
  vertical-align: middle;
  border-bottom: 2px solid #F6F6F6;
}

.invoice-record-item {
  align-self: center;
  padding-left: 16px;
  font-family: Basier Square;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.invoice-record-row {
  border-radius: 4px;
  height: 54px;
  background-color: #FFF;
  vertical-align: middle;
  border-bottom: 2px solid #F6F6F6;
}

.icon-style {
  text-align: center;
  padding: 0px;
  cursor: pointer;
}

.mobile-invoice-layout{
  overflow-x: auto;
}

.mobile-invoice-content{
  width: 700px;
}