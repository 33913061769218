.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.container-fluid {
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding: 0px 25px 50px 40px;
}

.menu .menu-divider {
  background-color: rgb(100, 100, 100);
}

.ant-menu.ant-menu-dark .ant-menu-item-:hover::before {
  background-color: transparent;
  font-family: "Arimo";
}

.shortcuts-container {
  padding: 0px 10px 25px 0px;
}

.shortcuts-container .shortcut-button {
  margin-right: 15px;
  margin-bottom: 15px;
  border: 0px;
  height: 84px;
  width: 84px;
  border-radius: 10px;
}

.dashboard-icon {
  color: #002333;
  font-size: 21px;
}

.ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-selected {
  padding: 0px;
}

/* .ant-popover-inner-content {
  background-color: red;
  padding: 1.5rem;
} */

.primary-button {
  color: white;
  border-radius: 3px;
  border: 0px;
  height: 40px;
}

.menu .ant-menu-item-selected {
  color: #35df90;
  background-color: transparent;
}

.chart-header {
  font-family: "Arimo";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.chart-header-mobile {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  margin-block-end: 6px;
}

.dashboard-header {
  font-family: "Arimo";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  color: #002333;
}

.dashboard-notification {
  margin-block-start: 16px;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}

.mobile-header-dashboard {
  font-family: "Basier Square";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  margin-block-start: 20px;
}

.sub-headers-dashboard {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.sub-header-home {
  padding-top: 18px;
  font-family: "Arimo";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  color: #002333;
}

.dashboard-profile-img {
  max-width: 175px;
  border-radius: 50%;
}

.dashboard-nav-btn {
  font-family: "Arimo";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  height: 60px;
  border-radius: 5px;
}

.dashboard-nav-btn-mobile {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  height: 60px;
  border-radius: 5px;
  margin-block-end: 10px;
}

.row-margin-end-20 {
  margin-block-end: 20px;
}
