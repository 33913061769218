.project-date-picker .ant-picker-input > input {
  color: white;
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  box-shadow: none;
}

.project-bar {
  place-items: center;
  height: 36px;
  background-color: #002333;
  width: 100%;
  color: white;
  border-radius: 5px;
}

.project-bar-icons {
  text-align: center;
  font-size: 16px;
  color: white;
}

.project-bar-icons:hover {
  text-align: center;
  font-size: 16px;
  color: #35df90;
}

.project-date-bar {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 4px;
  margin: -4px;
}

.projects-card-list-item::selection {
  color: black;
  margin-block-end: 20px;
  background-color: white;
  height: 130px;
}

.projects-card-list-item {
  width: 100%;
  color: black;
  margin-block-end: 20px;
  background-color: white;
  min-height: 130px;
  cursor: pointer;
}

.projects-card-header {
  font-family: "Arimo";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #002333;
  padding: 22px 35px;
  /* border-bottom: 1px solid #F6F6F6; */
}

.projects-card-detail-header {
  text-align: center;
  padding: 9px;
}

.sub-detail-1 {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #a5a6a7;
}

.sub-detail-2 {
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 3px solid #35df90;
}

.sub-detail-3 {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.projects-overview-column-freelance {
  border-top: 3px solid #eeeeee;
  padding: 24px 64px;
}

.add-side-border {
  border-right: 3px solid #eeeeee;
}

.projects-overview-column-mobile {
  border-top: 3px solid #f9f9fc;
  padding: 24px 24px;
}

.projects-overview-column-mobile-col {
  border-right: 3px solid #f9f9fc;
}

.projects-overview {
  background-color: white;
  padding: 24px 54px;
  text-align: center;
}

.projects-overview-header {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  color: #a5a6a7;
  margin-bottom: 8px;
}

.projects-overview-detail {
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  color: #002333;
}

.project-details-highlighted {
  margin-block-start: 5px;
  background-color: #35df90;
  color: white;
  height: 36px;
  padding: 7px;
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.freelancer-list-header {
  text-align: center;
  background-color: #fafafa;
  padding: 16px;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  border-bottom: 1px solid #e8e8e8;
}

.project-date-picker-hourly .ant-picker-input > input {
  color: #fff;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 18px;
  cursor: pointer;
  box-shadow: none;
}

.freelancer-list-item {
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  padding: 16px;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #595959;
  border-bottom: 1px solid #e8e8e8;
}

.freelancer-list-item-hightlight {
  color: #e84e30;
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px 8px;
}

.freelancer-details-header {
  margin-top: 32px;
  font-family: "Arimo";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #002333;
}

.freelancer-details-header-sub {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #002333;
}

.freelancer-details-data {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #002333;
  overflow-wrap: anywhere;
  place-items: center;
  width: 100%;
}

.freelancer-details-divider {
  padding: 0px;
  margin: 14px 0px;
  background-color: #f6f6f6;
}

.freelancer-details-icon {
  color: #a5a6a7;
  margin-right: 8px;
  font-size: 18px;
}

.project-details-button-1,
.project-details-button-1:hover {
  width: 90%;
  height: 35px;
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  overflow: hidden;
  margin-right: 14px;
  border: 0px;
  background-color: #eeeeee;
}

.hourly-overview-header-freelancer {
  background-color: #fafafa;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 16px;
  border: 0px;
  border-bottom: 1px solid #e8e8e8;
}

.hourly-overview-item-freelancer {
  background-color: #fff;
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 12px;
  border: 0px;
  border-bottom: 1px solid #e8e8e8;
}

.item-shadow {
  -moz-box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5);
}

.hourly-overview-btn {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #664ff3;
}

.freelance-profile {
  margin-left: 10px;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border-radius: 1px solid white;
}

.freelancer-details-mobile-header {
  height: 72px;
  width: 100%;
  background-color: #002333;
  display: flex;
  align-items: center;
}

span.freelancer-details-mobile {
  padding-left: 16px;
  font-family: "Basier Square";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.project-date-picker .ant-picker-input > input {
  color: white;
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  box-shadow: none;
}

.project-bar {
  place-items: center;
  height: 36px;
  background-color: #002333;
  width: 100%;
  color: white;
  border-radius: 5px;
}

.project-bar-icons {
  text-align: center;
  font-size: 16px;
  color: white;
}

.project-bar-icons:hover {
  text-align: center;
  font-size: 16px;
  color: #35df90;
}

.project-date-bar {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 4px;
  margin: -4px;
}

.projects-card-list-item::selection {
  color: black;
  margin-block-end: 20px;
  background-color: white;
  height: 130px;
}

.projects-card-list-item {
  width: 100%;
  color: black;
  margin-block-end: 20px;
  background-color: white;
  min-height: 130px;
}

.projects-card-header {
  font-family: "Arimo";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #002333;
  padding: 22px 35px;
  border-bottom: 1px solid #f6f6f6;
}

.projects-card-detail-header {
  text-align: center;
  padding: 9px;
}

.sub-detail-1 {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #a5a6a7;
}

.sub-detail-2 {
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 3px solid #35df90;
}

.sub-detail-3 {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.project-details-layout {
  padding: 55px 64px 64px 64px;
}

.project-details-layout__vacancy {
  height: 83vh;
  overflow-y: scroll;
}

.project-details-layout-mobile {
  padding: 44px 24px 24px 24px;
}

.project-details-inner {
  border-top: 3px solid #f9f9fc;
  padding: 12px 64px 24px 64px;
}

.project-details-inner-mobile {
  border-top: 3px solid #f9f9fc;
  padding: 12px 24px 24px 24px;
}

.project-details-header {
  font-family: "Arimo";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
}

.project-details-button {
  margin-right: 22px;
}

.project-details-button-mobile-right {
  margin-right: 14px;
  margin-bottom: 14px;
}

.project-details-button-style,
.project-details-button-style:hover {
  width: 90%;
  height: 35px;
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  overflow: hidden;
  margin-right: 14px;
  border: 0px;
  background-color: #f6f6f6;
}

.project-details-button-style:active {
  background-color: 664ff3 !important;
}

.projects-overview-column {
  border-top: 3px solid #f9f9fc;
  border-right: 3px solid #f9f9fc;
  padding: 24px 64px;
}

.projects-overview-column-mobile {
  border-top: 3px solid #f9f9fc;
  padding: 24px 24px;
}

.projects-overview-column-mobile-col {
  border-right: 3px solid #f9f9fc;
}

.projects-overview {
  background-color: white;
  padding: 24px 54px;
  text-align: center;
}

.projects-overview-header {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 10px; */
  letter-spacing: 0em;
  color: #a5a6a7;
  margin-bottom: 8px;
}

.projects-overview-detail {
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 14px; */
  letter-spacing: 0em;
  color: #002333;
}

.project-details-highlighted {
  margin-block-start: 5px;
  background-color: #35df90;
  color: white;
  height: 36px;
  padding: 7px;
  font-family: "Basier Square";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.hourly-overview-colum {
  background-color: #f6f6f6;
  text-align: center;
  padding: 24px;
}

.hourly-overview-colum-mobile {
  background-color: #f6f6f6;
  text-align: center;
  padding: 24px 2%;
}

.hourly-detail-item-header {
  width: 100%;
  font-family: "Arimo";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 16px 36px;
  border-bottom: 1px solid #d9d9d9;
}

.hourly-detail-item-body {
  padding: 0px 36px;
}

.hourly-detail-item-body-mobile {
  padding: 0px 16px;
}

.hourly-detail-item-title {
  font-family: "Arimo";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #664ff3;
  border-bottom: 3px solid #664ff3;
  margin-block-end: 18px;
}

.files-header {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #a5a6a7;
  margin: 24px auto 32px auto;
}

.upload-box-text {
  font-family: "Basier Square";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 12px;
}

.upload-icon {
  font-size: 16px;
  color: #664ff3;
  margin-right: 10px;
}

.project-horly-form-label {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
}

.project-horly-form-body {
  overflow-wrap: break-word;
  margin: 16px auto auto auto;
  /* background-color: red; */
  font-family: "Basier Square";
  font-size: 14px;
  /* font-style: normal; */
  /* font-weight: 400; */
  line-height: 22px;
  /* letter-spacing: 1px; */
  text-align: left;
  padding: auto 36px !important;
}

.project-horly-form-edit-btn,
.project-horly-form-edit-btn:hover {
  background-color: #002333;
  color: white;
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 10px;
}

.project-details-label {
  font-family: "Basier Square";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
}

img.freelancer-profile {
  height: 58px;
  width: 58px;
  right: 27px;
  position: relative;
  border-radius: 50%;
}

span.specialization {
  font-family: "Basier Square";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #664ff3;
  padding: 5px;
  color: white;
  border-radius: 2px;
  margin-right: 15px;
}

.freelancer-brief-intro {
  font-family: "Basier Square";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #a5a6a7;
  margin-block-start: 16px;
}

.freelancer-card-title {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-block-end: 12px;
}

.vacancies-divider-vertical {
  margin: 0px;
  margin-right: 30px;
  height: 100px;
  background-color: black;
  width: 3px;
}

.mobile-card-header {
  font-family: "Basier Square";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  color: #002333;
  margin-bottom: 6px;
}

.mobile-card-body {
  font-family: "Basier Square";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #a5a6a7;
}

.add-new-vecancy-btn {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.vacancies-freelancer-detail-card-delete {
  font-size: 19px;
  color: #a5a6a7;
  cursor: pointer;
}

.create-project-header {
  font-family: "Arimo";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}

.add-project-btn {
  font-family: "Arimo";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
