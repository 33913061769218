.activity-list-item {
  height: 54px;
  font-family: 'Basier Square';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: 002333;
  background-color: white;
  margin-block-end: 2px;
  padding: 16px;
  min-height: 54px;
  width: 100%;
}